import Vue from "vue";
import { eventBus } from './main'
Vue.mixin({
    data() {
      return {
        ApiBaseURL: process.env.VUE_APP_API_BASEURL,
        // ApiBaseURL: process.env.VUE_APP_API_BASEURL_LOCAL,
        // ApiBaseURL: process.env.VUE_APP_API_BASEURL_MUTEX,
      };
    },
    created(){
        
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        removePropertyInObject(obj, property){
            const { [property]: unused, ...rest } = obj
            return rest
        },
        getOverallExperience(a){
            var b = 0
            a.map((item)=>{
                b = item.years
            })
            return b;
        },
        openDoc(){
            window.open('https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', '_blank')
        },
        isLoading(show){
            eventBus.$emit('showLoading', show);
        },
        displaySuccess(message) {
            this.$root.$bvToast.toast(message, {
                // title: 'Success',
                variant: 'success',
                solid: true,
                autoHideDelay: 4000,
                noCloseButton: true
            })
        },
        displayError(message) {
            this.$root.$bvToast.toast(message, {
                // title: 'Error',
                variant: 'danger',
                solid: true,
                autoHideDelay: 4000,
                noCloseButton: true
            })
        },
    }
});