<template>
  <div id="app">
    <b-overlay
      class=""
      :show="showOverlay"
      opacity="0.95"
      spinner-variant="primary"
      spinner-type="grow"
      rounded="sm"
    >
      <router-view></router-view>
    </b-overlay>
  </div>
</template>

<script>
import { eventBus } from './main'
export default {
  name: 'App',
  created(){
    eventBus.$on('showLoading', (data) => {
      this.showOverlay = data;
    })
  },
  data(){
    return{
      showOverlay: false,
    }
  },
}
</script>

<style>
#app .b-overlay{
  z-index: 1051 !important;
  position: fixed !important;
}

</style>
