import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
  count: 0,
  info: {
    profile_pic: '',
    name: 'Super Admin'
  },
  doctors: [],
  patients: [],
  lessons: [],
  plans: [],
  users: [],
}

  // getters
const getters = {
  getInfo(state) {
      return state.info
  },
  plans(state) {
      return state.plans
  },
  lessons(state) {
      return state.lessons
  },
  users(state) {
      return state.users
  },
  doctors(state) {
      return state.doctors
  }
}
  
// actions
const actions = {
    async getusers ({ commit }, info) {
      commit('setusers', info)
    },
    async getlessons ({ commit }, data) {
        commit('setlessons', data)
    },
    async getplans ({ commit }, data) {
        commit('setplans', data)
    }
}
  
// mutations
const mutations = {
    setusers (state, info) {
      state.users = info
    },
    setlessons (state, info) {
        state.lessons = info
    },
    setplans (state, info) {
        state.plans = info
    },
}
  
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
})