import Vue from 'vue'
import moment from 'moment'
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('timeleft', function (value) {
    let myStart = "01:30:00 am";
    let myEnd = new Date();
    function getTimeDiff(start, end) {
        return moment.duration(moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a")));
    } 
    let diff = getTimeDiff(myStart, myEnd)
    return  `${diff.hours()}:${diff.minutes()}:${diff.seconds()}`;
})

Vue.filter('tenwords', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.length >= 10 ? value.substring(0,10)+".." : value
})

Vue.filter('fifteensword', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.length >= 18 ? value.substring(0,18)+".." : value
})