import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import { BootstrapVue, IconsPlugin  } from 'bootstrap-vue'
import * as rules from "vee-validate/dist/rules";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
import './assets/scss/style.scss'
import router from "./router";

// Install VeeValidate rules
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate";
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
  if(rule == 'confirmed'){
    extend(rule, {
      ...rule,
      message: "Password does not match"
    });
  }
});


// font awesome icon
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from  '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas, far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// apex chart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

//moment 
Vue.use(require('vue-moment'));

//mixin
import './mixin'

//filters
import './filters'

// swiper
// require('swiper/dist/css/swiper.css');

//store
import store from './store';  

// creating an event bus.
export const eventBus = new Vue(); 

//axios 
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// firesbase
import './firebase'

//lodash
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
